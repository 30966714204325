<template>
	<lassoLayout class="page-login">
		<div class="page_form_layout full_page">
			<v-container class="px-4">
				<div class="wrap_form_layout">
					<div class="form-card">
						<h2 class="login">Profile Basics</h2>
						<div class="form">
							<div class="full_name">
								<label>Full Name <span class="note">(not your artist name)</span></label>
								<input 
									autofocus 
									type="text" 
									v-model="fullName" 
									autocomplete="off"
									maxlength="30"
									pattern="[A-Za-z0-9\s\-\.,]*"
									title="Only letters, numbers, spaces, hyphens, dots, and commas are allowed"
									@input="$v.fullName.$touch()"
								>
								<div class="error-msg" v-if="!$v.fullName.isNameValid">Only alphanumeric characters, hyphen, dot, comma and space</div>
								<div class="error-msg" v-if="!$v.fullName.maxLength">30 characters max</div>
							</div>
							<div class="location">
								<label>Location</label>
								<vue-google-autocomplete
									id="location"
									classname="form-control"
									placeholder="City, State"
									types="(cities)"
									v-on:keyup="keypressLocation"
									v-on:placechanged="getAddressData">
								</vue-google-autocomplete>
								<div class="error-msg" v-if="!$v.location.isLocationValid">Only alphanumeric characters, hyphen and space</div>
								<div class="error-msg" v-if="!$v.location.maxLength">200 characters max</div>
							</div>
							<div class="roles">
								<label>I am a... <span class="note">(select all that apply)</span></label>
								<div class="role-tags">
									<button 
										type="button" 
										class="role-tag" 
										:class="{ active: userRoles.includes('Producer') }"
										@click="toggleRole('Producer')">
										<span class="icon">🎵</span>
										Producer
									</button>
									<button 
										type="button" 
										class="role-tag" 
										:class="{ active: userRoles.includes('Promoter') }"
										@click="toggleRole('Promoter')">
										<span class="icon">📣</span>
										Promoter
									</button>
									<button 
										type="button" 
										class="role-tag" 
										:class="{ active: userRoles.includes('Vendor') }"
										@click="toggleRole('Vendor')">
										<span class="icon">🏷️</span>
										Vendor
									</button>
									<button 
										type="button" 
										class="role-tag" 
										:class="{ active: userRoles.includes('Deco Artist') }"
										@click="toggleRole('Deco Artist')">
										<span class="icon">🎨</span>
										Deco Artist
									</button>
									<button 
										type="button" 
										class="role-tag" 
										:class="{ active: userRoles.includes('DJ') }"
										@click="toggleRole('DJ')">
										<span class="icon">🎧</span>
										DJ
									</button>
									<button 
										type="button" 
										class="role-tag" 
										:class="{ active: userRoles.includes('Fan') }"
										@click="toggleRole('Fan')">
										<span class="icon">⭐</span>
										Fan
									</button>
								</div>
							</div>
						</div>

						<button v-if="!$v.fullName.$invalid" type="button" class="c2a_btn" v-ripple @click="updateProfile"
								:class="{'loading': loader}" :disabled="loader">
							Continue
							<v-progress-circular :width="2" indeterminate></v-progress-circular>
							<span class="arrow">
								<span class="line_svg"></span>
								<svg width="7" height="10" viewBox="0 0 7 10" fill="none">
									<path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
								</svg>
							</span>
						</button>
						<button v-else type="button" class="c2a_btn" disabled>Continue</button>
					</div>
				</div>
			</v-container>
		</div>
	</lassoLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {maxLength, alphaNum} from 'vuelidate/lib/validators';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import {helpers} from 'vuelidate/lib/validators';
import lassoLayout from "@/layouts/lassoLayout.vue";

export default {
	name: 'profile-basics',
	components: {
		lassoLayout,
		VueGoogleAutocomplete
	},
	data() {
		return {
			loader: false,
			fullName: '',
			location: '',
			userRoles: [],
		}
	},
	watch: {
		'$route'(to, from) {
			this.getProfileData();
		},
		userRoles(newRoles) {
			console.log('Local roles updated (not saved yet):', newRoles);
		}
	},

	// Validations
	validations: {
		fullName: {
			isNameValid: helpers.regex('isNameValid', /^[A-Za-z0-9\s\-\.,]*$/i),
			maxLength: maxLength(30)
		},
		location: {
			isLocationValid: helpers.regex('isLocationValid', /^[A-Za-z0-9-.,\sа-яА-Яєіїґё]*$/i),
			maxLength: maxLength(200)
		}
	},
	computed: {
		...mapGetters(['userID', 'AUTH_DATA', 'USER', 'PROFILE']),
	},
	mounted() {
		this.getProfileData();
	},
	methods: {
		...mapActions(['CHANGE_USER_DATA', 'GET_PROFILE']),
		keypressLocation(event) {
			this.location = event.target.value;
		},
		getAddressData: function (addressData, placeResultData) {
			if (addressData.country === 'United States') {
				this.location = `${addressData.locality},  ${addressData.administrative_area_level_1}, ${addressData.country}`;
			} else {
				this.location = `${addressData.locality}, ${addressData.country}`;
			}
		},

		updateProfile() {
			this.loader = true;
			console.log('Sending profile update with roles:', this.userRoles);

			if (!this.location) {
				this.location = 'Planet Earth';
			}
			if (!this.fullName) {
				this.fullName = this.AUTH_DATA.userName.split('@')[0];
			}

			// Transform roles array into individual boolean fields
			const userData = {
				id: this.userID,
				address: this.location.trim(), // TODO: rename address to location ~backend~
				fullName: this.fullName.trim(),
				isProducer: this.userRoles.includes('Producer'),
				isPromoter: this.userRoles.includes('Promoter'),
				isVendor: this.userRoles.includes('Vendor'),
				isDecoArtist: this.userRoles.includes('Deco Artist'),
				isDj: this.userRoles.includes('DJ')
				// Fan role is not sent to API
			}
			console.log('Sending user data:', userData);
			
			this.CHANGE_USER_DATA(userData)
				.then(() => {
					console.log('Profile update successful, refreshing profile data');
					// Refresh profile data to sync roles
					return this.GET_PROFILE();
				})
				.then(() => {
					this.$router.push({name: 'memberships'})
						.catch(() => {
							this.loader = false;
						});
				})
				.catch(err => {
					console.error(`updateProfile, CHANGE_USER_DATA ${err}`);
					this.loader = false;
				});
		},
		getProfileData() {
			this.GET_PROFILE()
				.then(() => {
					// Initialize roles from profile data
					const roles = [];
					if (this.PROFILE?.isProducer) roles.push('Producer');
					if (this.PROFILE?.isPromoter) roles.push('Promoter');
					if (this.PROFILE?.isVendor) roles.push('Vendor');
					if (this.PROFILE?.isDecoArtist) roles.push('Deco Artist');
					if (this.PROFILE?.isDj) roles.push('DJ');
					this.userRoles = roles;
				})
				.catch(err => {
					console.error(`profile-basics, GET_PROFILE ${err}`);
				});
		},
		toggleRole(role) {
			const index = this.userRoles.indexOf(role);
			if (index === -1) {
				this.userRoles.push(role);
			} else {
				this.userRoles.splice(index, 1);
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/styles/pages/_profile-basics.scss';
</style>